import data from "./data";

const MainPage = () => {
  let mot;

  const bloc = {
    textAlign: "center",
    margin: "auto",
    position: "absolute",
    width: "100%",
  };

  const motdujour = {
    textAlign: "center",
    fontSize: "40px",
    fontWeight: "bold",
    width: "100%",
    display: "block",
  };

  const myIframe = {
    width: "90%",
    height: "500px",
  };

  const buttonStyle1 = { 
      backgroundImage: "linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%)",
      borderRadius: "8px",
      borderStyle: "none",
      boxSizing: "border-box",
      color: "#FFFFFF",
      cursor: "pointer",
      flexShrink: "0",
      fontFamily: '"Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
      fontSize: "16px",
      fontWeight: "500",
      height: "4rem",
      padding: "0 1.6rem",
      textAlign: "center",
      textShadow: "rgba(0, 0, 0, 0.25) 0 3px 8px",
      transition: "all .5s",
      userSelect: "none",
      marginRight:"10px",
      fontWeight:"bold",
  }

  const buttonStyle2 = { 
    backgroundImage: "linear-gradient(92.88deg, rgb(87, 202, 63) 9.16%, rgb(59, 117, 54) 43.89%, rgb(47, 92, 26) 64.72%)",
    borderRadius: "8px",
    borderStyle: "none",
    boxSizing: "border-box",
    color: "#FFFFFF",
    cursor: "pointer",
    flexShrink: "0",
    fontFamily: '"Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
    fontSize: "16px",
    fontWeight: "500",
    height: "4rem",
    padding: "0 1.6rem",
    textAlign: "center",
    textShadow: "rgba(0, 0, 0, 0.25) 0 3px 8px",
    transition: "all .5s",
    userSelect: "none",
    fontWeight:"bold",
}

  const dicoInStorage = localStorage.getItem("dictionnaire")?.toString();
  let dictionnaire = [];
  if (dicoInStorage) {
    dictionnaire = JSON.parse(dicoInStorage);
  } else {
    localStorage.setItem("dictionnaire", JSON.stringify(data));
  }

  function getNewMot() {
    const m = dictionnaire[Math.floor(Math.random() * dictionnaire.length)];
    if (m.appris === "true") {
      getNewMot();
    } else {
      return m;
    }
  }

  mot = getNewMot();

  const url =
    "https://www.le-dictionnaire.com/definition/" + mot.mot + "#infos";

  console.log(url);

  function newWord() {
    window.location.reload();
  }

  function setAppris() {
    if (window.confirm("Valider comme appris ?")) {
      const objIndex = dictionnaire.findIndex((obj) => obj.mot == mot.mot);
      dictionnaire[objIndex].appris = "true";
      localStorage.setItem("dictionnaire", JSON.stringify(dictionnaire));

      window.location.reload();
    }
  }

  return (
    <div style={bloc}>
      <div id="motdujour">
        <h2>Le mot du jour est</h2>
        <p>
          <span id="mot" style={motdujour}>
            {mot.mot}
          </span>
        </p>
        <div id="definition"></div>
      </div>

      <div id="def">
        <iframe src={url} title="Définition" style={myIframe}></iframe>
      </div>

      <div id="btn_list" style={{marginTop:20 + 'px'}}>
        <button type="button" value="Nouveau mot" onClick={newWord} style={buttonStyle1}>
          Nouveau mot
        </button>
        <button type="button" value="Appris" onClick={setAppris} style={buttonStyle1}>
          Considérer comme appris
        </button>
      </div>
    </div>
  );
};

export default MainPage;
